import {
  Button,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import { graphql, Link } from 'gatsby';
import { capitalize, keyBy, sortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Search, Twitter } from 'react-feather';
import { BlogTopic } from '../../components/BlogTopic';
import { Centered } from '../../components/Centered';
import {
  blogsToChartData,
  RankAreaChart,
  RankChart
} from '../../components/Charts/RankChart';
import { DevLogo } from '../../components/DevLogo';
import { HeroPattern } from '../../components/HeroPattern';
import { HighlightWhite } from '../../components/Highlight';
import { MiniPoints, Points, toPoints } from '../../components/Points';
import { Author } from '../../components/ProAuthor';
import {
  ControlButton,
  ControlsContainer,
  RankingPageHeader
} from '../../components/RankingPageHeader';
import { SEO } from '../../components/SEO';
import { SubmitBlogDialog } from '../../components/SubmitBlogDialog';
import { TopicBadge } from '../../components/TopicBadge';
import { TrendingLink, TrendingPost } from '../../components/TrendingPage';
import { TrendsNav } from '../../components/TrendsNav';
import { WhiteButton } from '../../components/WhiteButton';
import { useDialogState } from '../../hooks/useDialogState';
import { useMappedValue } from '../../hooks/useMappedHook';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { LayoutWithoutHeader as Layout } from '../../layouts/Layout';
import {
  getTopPageSortedByLastNDays,
  Mode,
  NEW_BLOG_CUTOFF_DAYS,
  useAllTopicStats,
  useBlogsForTopicSortedByLastNDays,
  useMetaStats
} from '../../services/ranking';
import styled from '../../styled';
import { SubmissionTopic } from '../../types/Submission';
import { Topic } from '../../types/Topic';

const PAGE_SIZE = 5;
const DEFAULT_LIMIT = 15;

const ChartWrapper = styled('div')`
  background-color: #111216;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

const ChartWrapperInner = styled('div')`
  max-width: 1120px;
  margin: ${(p) => p.theme.spacing(1)}px auto;
  padding: ${(p) => p.theme.spacing(3)}px 0;

  div > div {
    overflow: hidden;
  }
`;

const ChartWrapperTitleWrapper = styled('div')`
  margin-top: ${(p) => p.theme.spacing(3)}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1120px;
  margin: 0 auto;

  @media (max-width: 800px) {
    display: block;
    text-align: center;
    margin-bottom: ${(p) => p.theme.spacing(2)}px;
  }
`;

const UnderlinkOnHoverLink = styled('a')`
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.1s ease-in;

  &:hover {
    border-bottom: 3px solid;
  }
`;

const InfoMessage = styled('div')`
  background: linear-gradient(130.46deg, #0050b3 4.34%, #1890ff 99.39%);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  line-height: 30px;
  border-radius: 8px;
  padding: ${(p) => p.theme.spacing(2)}px;
`;

const InfoFooter = styled('div')`
  display: grid;
  max-width: 1120px;
  margin: ${(p) => p.theme.spacing(2)}px auto;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    margin: ${(p) => p.theme.spacing(3)}px ${(p) => p.theme.spacing(3)}px;
  }

  @media (max-width: 720px) {
    grid-template-columns: repeat(1, 1fr);
  }

  p {
    font-size: 1rem;
    line-height: 2rem;
  }

  a {
    border-bottom: 1px solid;
  }
`;

const AllBlogsWrapper = styled('div')`
  height: 600px;
  color: ${(p) => p.theme.palette.grey[200]};

  @media (max-width: 800px) {
    height: auto;
  }
`;

const AllBlogsWrapperInner = styled('div')`
  padding: ${(p) => p.theme.spacing(3)}px 0;
  max-width: 1120px;
  margin: 0 auto;

  @media (max-width: 800px) {
    padding: ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(4)}px;
  }
`;

export const Badge = styled('div')`
  position: relative;
  top: -2px;
  font-size: 8px;
  line-height: 14px;
  padding: 4px 8px;
  background-color: ${(p) => p.theme.palette.grey[900]};
  border-radius: 4px;
  font-weight: 900;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 8px;
`;

const Hero = styled('div')`
  padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(3)}px
    ${(p) => p.theme.spacing(2)}px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4) 37.5%,
    rgba(0, 0, 0, 0) 100%
  );
`;

const HeroInner = styled('div')`
  position: relative;
  z-index: 2;
  max-width: 1120px;
  margin: 0 auto;
`;

const Headline = styled('h1')`
  font-size: 48px;
  max-width: 500px;
  color: ${(p) => p.theme.palette.primary.main};
  font-weight: 900;

  @media (max-width: 1024px) {
    font-size: 2.5rem;
  }

  @media (max-width: 800px) {
    font-size: 2rem;
    margin: 0 auto;
    text-align: center;
  }
`;

const UnderlinedA = styled('a')`
  border-bottom: 1px solid;
  padding-bottom: 3px;
`;

const UnderlinedLink = styled(Link)`
  border-bottom: 1px solid;
  color: ${(p) => p.theme.palette.grey[300]};
`;

const Subheadline = styled('p')`
  color: ${(p) => p.theme.palette.grey[300]};
  line-height: 1.8rem;
  font-size: 1rem;
  max-width: 465px;

  strong {
    color: ${(p) => p.theme.palette.grey[100]};
  }

  @media (max-width: 800px) {
    text-align: center;
    margin: ${(p) => p.theme.spacing(2)}px auto 0;
  }
`;

const TrendsBody = styled('div')`
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 5fr 2fr;
  grid-column-gap: ${(p) => p.theme.spacing(10)}px;
  max-width: 1120px;
  margin: ${(p) => p.theme.spacing(3)}px auto ${(p) => p.theme.spacing(6)}px;

  @media (max-width: 1024px) {
    margin: 0 auto ${(p) => p.theme.spacing(3)}px;
    padding: 24px;
    grid-template-columns: 1fr;
  }
`;

const SectionTitleGray = styled('h2')`
  font-size: 16px;
  color: ${(p) => p.theme.palette.primary.main};
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:not(:first-of-type) {
    margin-top: ${(p) => p.theme.spacing(6)}px;
  }
`;

const TopBlogScreenshotWrapper = styled('div')`
  position: absolute;
  top: 60px;
  right: 40px;
  width: 470px;
  height: 284px;
  overflow: hidden;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  background-color: ${(p) => p.theme.palette.grey[700]};

  @media (max-width: 1024px) {
    display: none;
  }

  img {
    max-width: 100%;
  }
`;

const TopBlogDescription = styled('div')`
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: ${(p) => p.theme.palette.primary.dark};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  color: ${(p) => p.theme.palette.grey[100]};
  padding: ${(p) => p.theme.spacing(0)}px ${(p) => p.theme.spacing(3)}px
    ${(p) => p.theme.spacing(2)}px;
  box-shadow: -4px -4px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 24px;
  width: 245px;

  @media (max-width: 1024px) {
    display: none;
  }

  p a {
    border-bottom: 1px solid;
  }
`;

const RowScreenshotWrapper = styled('div')`
  grid-area: screenshot;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

const ScreenshotOverlay = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #096dd9;
  opacity: 0.2;
  transition: opacity 0.1s ease-in;
`;

const RowInner = styled('div')`
  padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(3)}px;
  grid-area: description;
  border-right: 1px solid #191c20;

  @media (max-width: 700px) {
    padding: ${(p) => p.theme.spacing(3)}px ${(p) => p.theme.spacing(3)}px;
    border-right: none;
    border-top: 1px solid ${(p) => p.theme.palette.grey[800]};
  }
`;

const Row = styled('div')`
  background: #111216;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 190px 1fr 100px;
  grid-template-areas: 'screenshot description points';
  height: 142px;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
  border: 1px solid transparent;
  transform: scale(1);
  transition: transform 0.2s ease-in;

  &:hover {
    transform: scale(1.02);
    border: 1px solid ${(p) => p.theme.palette.primary.dark};
    box-shadow: 0 5px 20px 0 rgb(24 144 255 / 22%);

    .bfd-overlay {
      opacity: 0;
    }
  }

  @media (max-width: 700px) {
    grid-template-columns: 3fr 86px;
    height: auto;
    grid-template-areas:
      'screenshot screenshot'
      'description points';
  }
`;

const RowTitle = styled('h3')`
  margin: 12px 8px 0 0;
  font-size: 1.3rem;
  font-weight: 600;
  display: inline-block;
`;

const TopicList = styled('div')`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  display: grid;
  grid-template-rows: repeat(10, 48px);
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(15, 48px);
  }

  @media (max-width: 720px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(25, 48px);
  }
`;

const AllBlogsTitleWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;

  @media (max-width: 800px) {
    display: block;
  }
`;

const HeroFade = styled('div')`
  position: absolute;
  height: 500px;
  width: 100%;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(360deg, #191c20 0%, rgba(25, 28, 32, 0) 28.79%);

  @media (max-width: 800px) {
    display: none;
  }
`;

const RankBadge = styled('div')`
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.palette.grey[100]};
  border-top: 1px solid ${(p) => p.theme.palette.grey[200]};
  border-bottom: 1px solid ${(p) => p.theme.palette.grey[200]};
  border-right: 1px solid ${(p) => p.theme.palette.grey[200]};
  color: ${(p) => p.theme.palette.grey[500]};
  box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.25);
  right: 0;
  top: 24px;
  font-weight: 600;
  font-size: 12px;
  height: 24px;
  padding-left: 2px;
  width: 30px;
  border-radius: 4px 0 0 4px;
`;

const IndexPage = ({
  data: {
    allTopic: { edges }
  }
}: {
  data: {
    allTopic: { edges: { node: Topic }[] };
  };
}) => {
  const [scoreRange, setScoreRange] = useState(2);
  const [mode, setMode] = useState<Mode>('all');
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [selectedChart, setSelectedChart] = useState<'volume' | 'rank'>(
    'volume'
  );
  const topics = edges.map((e) => e.node);
  const topicsByKey = keyBy(topics, 'id');
  const allRankedBlogs = useBlogsForTopicSortedByLastNDays(
    'OVERALL',
    mode,
    scoreRange
  );
  const firstRankedBlog = allRankedBlogs[0];

  const topTopics = useMappedValue(
    useAllTopicStats(
      topics
        .filter((t) => t.type === 'topic')
        .map((t) => t.id as SubmissionTopic)
    ),
    (ts) => sortBy(ts, (t) => -t.scoreLast30Days.curr)
  );

  const topCategories = useMappedValue(
    useAllTopicStats(
      topics
        .filter((t) => t.type === 'category')
        .map((t) => t.id as SubmissionTopic)
    ),
    (ts) => sortBy(ts, (t) => -t.scoreLast30Days.curr)
  );

  const monitoredBlogCount = useMetaStats().blogCount.OVERALL;
  const chunkableTopics = useMemo(() => {
    if (!search) {
      return [...topTopics];
    }

    const filteredTopics = topTopics.filter(
      (t) => topicsByKey[t.topic].name.toLowerCase().indexOf(search) !== -1
    );
    return filteredTopics;
  }, [topTopics, search]);

  useEffect(() => {
    if (limit === DEFAULT_LIMIT) {
      return;
    }
    const id = `blog-rank-${limit - PAGE_SIZE}`;
    const el = document.getElementById(id);
    el?.scrollIntoView({ behavior: 'smooth' });
  }, [limit]);

  const tweet =
    "Just found a cool place to discover developers' blogs for different tech stacks. Check it out!";
  const postUrl = 'https://bloggingfordevs.com/trends/';
  const twitterHref = `https://twitter.com/share?text=${tweet}&url=${postUrl}`;

  const isMobile = useMediaQuery('(max-width: 650px)');
  const chartData = useMemo(
    () =>
      blogsToChartData(
        allRankedBlogs.slice(0, 15),
        'absolute',
        isMobile ? 7 : 14
      ),
    [allRankedBlogs, isMobile]
  );

  console.log(firstRankedBlog);

  return (
    <Layout>
      <SEO
        seoTitle="Today's Best Developer Blogs on Any Tech Stack"
        title="Discover Today's Best Tech Blogs, Written by Developers"
        siteUrl="https://bloggingfordevs.com"
        socialSharingImage="/images/tech-blog-rankings-preview.png"
        useTitleTemplate={false}
        description="Find the best developer blogs across topics and tech stacks. Ranked by machines, curated by humans. Updated hourly."
        pathname="trends/"
      />
      <Hero>
        <HeroInner>
          <TrendsNav hideLinks to="/" mode="dark" />
          <Headline>
            Discover the best developer blogs on any tech stack
          </Headline>
          <Subheadline>
            <strong>
              The content Tech Twitter is talking about, minus the noise.
            </strong>{' '}
            Ranked by machines, curated by humans, updated hourly.
            <br />
            <UnderlinedLink to="/trends/how-it-works/">
              How it works
            </UnderlinedLink>{' '}
          </Subheadline>
          {firstRankedBlog && (
            <>
              <TopBlogScreenshotWrapper>
                <img
                  src={firstRankedBlog.screenshotUrl.l}
                  alt={firstRankedBlog.name}
                />
              </TopBlogScreenshotWrapper>
              <TopBlogDescription>
                <p>
                  In the last {scoreRange} days,{' '}
                  <a href={firstRankedBlog.url} target="_blank" rel="noopener">
                    {firstRankedBlog.name}
                  </a>{' '}
                  picked up{' '}
                  {toPoints(firstRankedBlog.stats, scoreRange).toLocaleString(
                    'en-US'
                  )}{' '}
                  points 🔥
                </p>
                {firstRankedBlog.topics.map((t) => (
                  <TopicBadge
                    variant="black"
                    to={`/${topicsByKey[t].slug}/`}
                    key={t}
                  >
                    {topicsByKey[t].name}
                  </TopicBadge>
                ))}
              </TopBlogDescription>
            </>
          )}
        </HeroInner>
        <HeroFade />
        <HeroPattern pattern="topography" patternColor="#003a8c" />
      </Hero>
      <TrendsBody>
        <div>
          <RankingPageHeader
            title="Top Developer Blogs • All Topics"
            scoreRange={scoreRange}
            setScoreRange={setScoreRange}
            mode={mode}
            setMode={setMode}
          />
          {!allRankedBlogs.length && (
            <>
              {mode === 'new' && (
                <Centered height={200}>
                  No new submissions in the last {NEW_BLOG_CUTOFF_DAYS} days.
                </Centered>
              )}
              {mode === 'all' && <Centered height={200}>No blogs.</Centered>}
            </>
          )}
          {allRankedBlogs.slice(0, limit).map((blog, i) => {
            const topPage = getTopPageSortedByLastNDays(
              blog,
              scoreRange
            ).filter((p) => p.pathname !== '/')[0];
            return (
              <Row key={blog.id} id={`blog-rank-${i}`}>
                <RowScreenshotWrapper>
                  <RankBadge>{i + 1}.</RankBadge>
                  <ScreenshotOverlay className="bfd-overlay" />
                  <img src={blog.screenshotUrl.m} alt={blog.name} />
                </RowScreenshotWrapper>
                <RowInner>
                  <RowTitle>
                    <UnderlinkOnHoverLink
                      href={blog.url}
                      target="_blank"
                      rel="noopener"
                    >
                      {blog.name}
                    </UnderlinkOnHoverLink>
                  </RowTitle>
                  {blog.topics.map((t) => (
                    <TopicBadge
                      variant="blue"
                      to={`/${topicsByKey[t].slug}/`}
                      key={t}
                    >
                      {topicsByKey[t].name}
                    </TopicBadge>
                  ))}
                  {topPage && (
                    <>
                      <br />
                      <TrendingPost>TRENDING POST:</TrendingPost>
                      <TrendingLink blog={blog} page={topPage} />
                    </>
                  )}
                </RowInner>
                <Points
                  size="small"
                  style={{ backgroundColor: 'transparent' }}
                  scores={blog.stats}
                  scoreRange={scoreRange}
                />
              </Row>
            );
          })}
          {allRankedBlogs.length > limit && (
            <Button
              style={{
                padding: '3px 6px',
                borderBottom: '1px solid',
                borderRadius: 0
              }}
              onClick={() => {
                const nextPage =
                  limit + PAGE_SIZE < allRankedBlogs.length
                    ? limit + PAGE_SIZE
                    : allRankedBlogs.length;
                setLimit(nextPage);
              }}
            >
              Load more blogs
            </Button>
          )}
        </div>
        <div style={{ position: 'relative', zIndex: 1 }}>
          <SectionTitleGray style={{ marginBottom: '30px' }}>
            Click a trending topic
          </SectionTitleGray>
          <div>
            {topTopics.slice(0, 10).map((stats) => (
              <BlogTopic key={stats.topic}>
                <Link to={`/${topicsByKey[stats.topic].slug}/`}>
                  <span>
                    <span className="svg-wrapper">
                      <DevLogo id={stats.topic} />
                    </span>
                    {topicsByKey[stats.topic].name}
                  </span>
                  <MiniPoints scores={stats} scoreRange={scoreRange} />
                </Link>
              </BlogTopic>
            ))}
          </div>
          <Typography
            variant="body1"
            component="p"
            paragraph
            style={{ marginTop: '6px' }}
          >
            <UnderlinedA
              href="#all-topics"
              onClick={(e) => {
                e.preventDefault();
                const el = document.getElementById('all-topics');
                el?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              See all topics
            </UnderlinedA>
          </Typography>
          <SectionTitleGray>Explore Blogs by Category</SectionTitleGray>
          <div>
            {topCategories.slice(0, 10).map((stats) => (
              <BlogTopic key={stats.topic}>
                <Link to={`/${topicsByKey[stats.topic].slug}/`}>
                  <span>{topicsByKey[stats.topic].name}</span>
                  <MiniPoints scores={stats} scoreRange={scoreRange} />
                </Link>
              </BlogTopic>
            ))}
          </div>
          <div style={{ marginTop: '36px', marginBottom: '12px' }}>
            <SectionTitleGray>Hello and Welcome!</SectionTitleGray>
            <Typography variant="body1" component="p" paragraph>
              <strong>Blogging for Devs Trends</strong> is meant to be a fun way
              to discover the most discussed technical content on Twitter,
              without doomscrolling your day away.
            </Typography>
            <Typography variant="body1" component="p" paragraph>
              To do this, we track <strong>{monitoredBlogCount}</strong> of the
              best technical blogs by developers and industry experts, across
              over two dozen categories.
            </Typography>
            <Typography variant="body1" component="p" paragraph>
              Points are weighted to reward mentions and replies by individual
              developers more than likes and retweets.
            </Typography>
            <Typography variant="body1" component="p" paragraph>
              You can help by recommending your favorite developer blogs &amp;
              blogs about software, or telling your friends and followers if you
              like this resource.
            </Typography>
            <Typography variant="body1" component="p" paragraph>
              Hope you find something cool here.
            </Typography>
            <Typography variant="body1" component="p" paragraph>
              <UnderlinedLink to="/trends/how-it-works/">
                Learn how it works
              </UnderlinedLink>
            </Typography>
            <Author
              color="black"
              name="Monica Lent"
              byline={<span>Creator, BloggingforDevs.com</span>}
              img="/images/my-face.jpg"
              badge={null}
            />
          </div>
          <InfoMessage style={{ marginTop: '24px' }}>
            <Typography variant="body1" component="p" paragraph>
              We'd love your help creating the best place to discover blogs by
              individual developers and technical folks.
            </Typography>
            <WhiteButton size="large" onClick={openDialog}>
              Recommend a blog
            </WhiteButton>
          </InfoMessage>
          <SectionTitleGray>Share this resource</SectionTitleGray>
          <Typography variant="body1" component="p" paragraph>
            The more people know about Trends, the more developers' blogs are
            discovered and followed. Help us get the word out!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              window.open(twitterHref, 'twitter-share', 'width=550,height=235');
            }}
          >
            <Twitter size={18} /> &nbsp; Share this on Twitter
          </Button>
        </div>
      </TrendsBody>
      {!!allRankedBlogs.length && (
        <div>
          <ChartWrapperTitleWrapper>
            <SectionTitleGray style={{ letterSpacing: '2px' }}>
              {isMobile ? 7 : 14}-Day History • Top 15
            </SectionTitleGray>
            <ControlsContainer>
              <ControlButton
                selected={selectedChart === 'volume'}
                onClick={() => {
                  setSelectedChart('volume');
                }}
              >
                Points
              </ControlButton>
              <ControlButton
                selected={selectedChart === 'rank'}
                onClick={() => {
                  setSelectedChart('rank');
                }}
              >
                Rank
              </ControlButton>
            </ControlsContainer>
          </ChartWrapperTitleWrapper>
          <ChartWrapper>
            <ChartWrapperInner>
              {selectedChart === 'volume' && (
                <RankAreaChart data={chartData} height={isMobile ? 300 : 600} />
              )}
              {selectedChart === 'rank' && (
                <RankChart data={chartData} height={isMobile ? 300 : 600} />
              )}
            </ChartWrapperInner>
          </ChartWrapper>
        </div>
      )}
      <AllBlogsWrapper id="all-topics">
        <AllBlogsWrapperInner>
          <AllBlogsTitleWrapper>
            <SectionTitleGray style={{ letterSpacing: '2px' }}>
              Discover more developer blogs
            </SectionTitleGray>
            <div>
              <TextField
                type="search"
                id="search"
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search by technology"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ color: '#767E87' }}
                    >
                      <Search size={18} />
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </AllBlogsTitleWrapper>
          {chunkableTopics.length === 0 && (
            <Centered height={400}>
              <strong>
                We're not tracking any blogs on{' '}
                <HighlightWhite>{capitalize(search)}</HighlightWhite> yet 😔
              </strong>
              <p>Want to suggest it to us? Give us a tweet!</p>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={(e: React.SyntheticEvent) => {
                  e.preventDefault();
                  const tweet = `Hey @bloggingfordevs! I'd love to see you add a section for blogs about "${search}" on your Trends page!`;
                  const postUrl = 'https://bloggingfordevs.com/trends/';
                  const newTwitterHref = `https://twitter.com/share?text=${tweet}&url=${postUrl}`;
                  window.open(
                    newTwitterHref,
                    'twitter-share',
                    'width=550,height=235'
                  );
                }}
              >
                <Twitter size={18} /> &nbsp; Recommend this topic
              </Button>
              <Button
                onClick={() => {
                  setSearch('');
                }}
              >
                Clear search
              </Button>
            </Centered>
          )}
          {chunkableTopics.length > 0 && (
            <div>
              <TopicList>
                {chunkableTopics.map((stats) => (
                  <BlogTopic key={stats.topic}>
                    <Link to={`/${topicsByKey[stats.topic].slug}/`}>
                      <span>
                        <span className="svg-wrapper">
                          <DevLogo id={stats.topic} />
                        </span>
                        {topicsByKey[stats.topic].name}
                      </span>
                      <MiniPoints scores={stats} scoreRange={scoreRange} />
                    </Link>
                  </BlogTopic>
                ))}
              </TopicList>
            </div>
          )}
        </AllBlogsWrapperInner>
      </AllBlogsWrapper>
      <InfoFooter>
        <div>
          <SectionTitleGray>Claim your blog</SectionTitleGray>
          <p>
            If your blog's been submitted by someone else and you want to update
            its categories or description, send an email to
            trends@bloggingfordevs.com via the email address listed on your
            website ✌️
          </p>
        </div>
        <div>
          <SectionTitleGray>Share this resource</SectionTitleGray>
          <p>
            The more people know about Trends, the more developers' blogs are
            discovered and followed. Help us get the word out!
          </p>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              window.open(twitterHref, 'twitter-share', 'width=550,height=235');
            }}
          >
            <Twitter size={18} /> &nbsp; Share this on Twitter
          </Button>
        </div>
        <div>
          <SectionTitleGray>Acknowledgements</SectionTitleGray>
          <p>
            These rankings were originally inspired by the Detailed.com list of{' '}
            <a href="https://detailed.com/50/" target="_blank">
              best blogs
            </a>
            . SVG Patterns are from{' '}
            <a href="https://www.heropatterns.com/" target="_blank">
              Hero Patterns
            </a>{' '}
            and many icons are from{' '}
            <a href="https://devicon.dev/" target="_blank">
              Devicon
            </a>
            . Thanks also to everyone who's submitted blogs and shared their
            feedback!
          </p>
        </div>
      </InfoFooter>
      <SubmitBlogDialog open={dialogOpen} onClose={closeDialog} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allTopic(sort: { fields: [name], order: ASC }) {
      edges {
        node {
          name
          relatedTopics
          slug
          title
          description
          type
          id
        }
      }
    }
  }
`;

export default IndexPage;
