import styled from '../styled';

export const BlogTopic = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 6px 9px;
    border-radius: ${(p) => p.theme.shape.borderRadius / 2}px;
    background-color: #111216;
    margin-bottom: ${(p) => p.theme.spacing(1)}px;
    transform: scale(1);
    transition: transform 0.1s ease-in;
    border: 1px solid transparent;

    &:hover {
      transform: scale(1.03);
      border: 1px solid ${(p) => p.theme.palette.primary.dark};
      box-shadow: 0 5px 20px 0 rgb(24 144 255 / 22%);
    }
  }

  span.svg-wrapper {
    width: 18px;
    display: inline-block;
    margin-right: 8px;
  }

  svg {
    width: 18px;
    position: relative;
    top: 3px;
  }
`;
