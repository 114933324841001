import styled from '../styled';
import { Button } from '@material-ui/core';

export const WhiteButton = styled(Button)`
  background: linear-gradient(86.61deg, #f4faff 23.95%, #e5ebf1 97.88%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: ${(p) => p.theme.palette.grey[800]};
  font-weight: 600;
  padding: 12px 24px;

  &:hover {
    color: ${(p) => p.theme.palette.grey[800]};
    background: linear-gradient(86.61deg, #f4faff 23.95%, #e5ebf1 97.88%);
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.2);
  }
`;
